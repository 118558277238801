import React from "react";
import { PageProps, graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Img, { FluidObject } from "gatsby-image";

import SEO from "../components/seo";
import Connections from "../assets/connections.svg";
import { replaceLineBreaks } from "../utils/text";

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema

export type FluidImage = {
  childImageSharp: { fluid: FluidObject };
};

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 800) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "IMG_0599.jpeg" }) {
      ...fluidImage
    }
  }
`;
const IndexPage: React.FC<PageProps<{
  imageOne: FluidImage;
}>> = ({ data: { imageOne } }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { t: t2 } = useTranslation("index");
  const name = t("seththee_name");
  const description = t("seththee_description", { name });
  const title = `${t("hotel")} ${name}`;
  const welcome = t2("welcome", { name });

  return (
    <main className="full-centered">
      <SEO pageTitle={title} language={language} description={description} />
      <figure className="hero">
        <Img
          title="Header image"
          alt="Greek food laid out on table"
          fluid={imageOne.childImageSharp.fluid}
        />
        <figcaption>
          <h1>{replaceLineBreaks(welcome)}</h1>
        </figcaption>
      </figure>
      <section>
        <Connections className="connections" />
        {t2("copy-1")}
      </section>
      <section>{t2("copy-2")}</section>
      <section>
        <strong>Every</strong> room has its individual style to accomodate to
        our guest's personal preferences.
      </section>
      <section>
        All rooms have double or twin bed, balcony, bathroom with hot shower,
        aircon, fridge, cable TV and free WiFi.
      </section>
      <section>Our staff speaks English, Thai and German.</section>
      <section>
        In our restaurant we offer Breakfast (American or Thai) and original
        Thai cuisine.
      </section>
      <section>
        For your vehicle's convenience we have a big parking lot behind the
        building.
      </section>
    </main>
  );
};

export default IndexPage;
